/* Import IntlTelInput base styles */
@import "intl-tel-input/styles";

:root {
  --color-translucent-emphasis-300: rgba(247, 247, 247, 0.1);
  --color-text-body-primary: #F7F7F7;
  --color-translucent-inverse-600: rgba(13, 13, 13, 0.48);
  --color-translucent-standard-500: rgba(247, 247, 247, 0.24);
  --color-translucent-standard-700: rgba(247, 247, 247, 0.64);
  --color-status-fatal-bright: #FF637F;
}


.iti {
  font-family: var(--font-body-primary, Roboto, sans-serif);
  font-size: 14px;
  width: 100%;
}

/* Override country list styling */
.iti__country-list {
  background-color: #1C1C1C;
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 8px 0;
  max-height: 350px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-family: var(--font-body-primary, Roboto, sans-serif);
  margin-top: 8px;
}

.iti__divider {
  border-bottom: 1px solid #333;
}

.iti__country.iti__highlight,
.iti__country:hover {
  background-color: #2A2A2A;
}

.iti--container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.iti__flag-container,
.iti__selected-flag {
  background-color: #1C1C1C;
  border-radius: 8px 0 0 8px;
  border-right: 1px solid var(--color-translucent-standard-500);
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.iti__selected-flag:hover {
  background-color: #2A2A2A;
}

.iti__arrow {
  border: none;
  width: 8px;
  height: 8px;
  border-right: 2px solid var(--color-text-body-primary);
  border-bottom: 2px solid var(--color-text-body-primary);
  transform: rotate(45deg);
  margin-top: -4px;
  margin-left: 12px;
  transition: transform 0.2s ease;
}

.iti__arrow--up {
  transform: rotate(-135deg);
  margin-top: 0;
}

.iti__country-name,
.iti__dial-code {
  color: var(--color-text-body-primary);
  font-size: 14px;
  font-family: var(--font-body-primary, Roboto, sans-serif);
}

.iti__search-container {
  padding: 8px 12px;
  background-color: #1C1C1C;
}



.iti__search-input:hover {
  border-color: var(--color-translucent-inverse-600);
}

.iti__search-input:focus {
  border-color: #F7F7F7;
  outline: none;
}

.iti__country {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Input styling */
.iti input {
  background-color: #1C1C1C;
  color: #fff;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--color-translucent-standard-500);
  font-size: 14px;
  padding: 0 12px 0 90px;
  font-family: var(--font-body-primary, Roboto, sans-serif);
  transition: all 0.2s ease-in-out;
}

.iti input:hover {
  border-color: var(--color-translucent-standard-500);
}

.iti input:focus {
  border-color: #F7F7F7;
  outline: none;
}

.iti input.is-invalid {
  border-color: var(--color-status-fatal-bright);
}

/* Fix full width */
.iti {
  width: 100%;
  display: block;
}

/* Adjust spacing for country flag and dial code */
.iti__selected-flag {
  padding-left: 12px;
}

.iti__flag-container {
  margin-right: 8px;
}

.iti__dropdown-content {
  background-color: #1C1C1C;
}

.iti--inline-dropdown .iti__dropdown-content {
  border-color: #1C1C1C;
  border-radius: 12px;
}

/* .iti__tel-input { 
  padding-left: 90px !important;
} */

/* .iti__selected-dial-code {
  margin-left: 6px;
} */

.iti__flag {
  margin-left: 6px;
}

.iti__search-input {
  background-color: #1C1C1C;
  color: #fff;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 8px 12px !important;
  width: 100%;
  font-size: 14px;
  font-family: var(--font-body-primary, Roboto, sans-serif);
}
