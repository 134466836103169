:root {
  --iti-hover-color: rgba(0, 0, 0, 0.05);
  --iti-border-color: #ccc;
  --iti-dialcode-color: #999;
  --iti-dropdown-bg: white;
  --iti-spacer-horizontal: 8px;
  --iti-flag-height: 12px;
  --iti-flag-width: 16px;
  --iti-border-width: 1px;
  --iti-arrow-height: 4px;
  --iti-arrow-width: 6px;
  --iti-triangle-border: calc(var(--iti-arrow-width) / 2);
  --iti-arrow-padding: 6px;
  --iti-arrow-color: #555;
  --iti-path-flags-1x: url(/_next/static/media/flags.192a0002.webp);
  --iti-path-flags-2x: url(/_next/static/media/flags@2x.580decae.webp);
  --iti-path-globe-1x: url(/_next/static/media/globe.98e105ca.webp);
  --iti-path-globe-2x: url(/_next/static/media/globe@2x.974df6f8.webp);
  --iti-flag-sprite-width: 3904px;
  --iti-flag-sprite-height: 12px;
  --iti-mobile-popup-margin: 30px;
}

.iti {
  position: relative;
  display: inline-block;
}
.iti * {
  box-sizing: border-box;
}
.iti__hide {
  display: none;
}
.iti__v-hide {
  visibility: hidden;
}
.iti__a11y-text {
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
}
.iti input.iti__tel-input,
.iti input.iti__tel-input[type=text],
.iti input.iti__tel-input[type=tel] {
  position: relative;
  z-index: 0;
  margin: 0 !important;
}
.iti__country-container {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: var(--iti-border-width);
}
.iti__selected-country {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border-radius: 0;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: none;
}
.iti__selected-country-primary {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 var(--iti-arrow-padding) 0 var(--iti-spacer-horizontal);
}
.iti__arrow {
  margin-left: var(--iti-arrow-padding);
  width: 0;
  height: 0;
  border-left: var(--iti-triangle-border) solid transparent;
  border-right: var(--iti-triangle-border) solid transparent;
  border-top: var(--iti-arrow-height) solid var(--iti-arrow-color);
}
[dir=rtl] .iti__arrow {
  margin-right: var(--iti-arrow-padding);
  margin-left: 0;
}
.iti__arrow--up {
  border-top: none;
  border-bottom: var(--iti-arrow-height) solid var(--iti-arrow-color);
}
.iti__dropdown-content {
  border-radius: 3px;
  background-color: var(--iti-dropdown-bg);
}
.iti--inline-dropdown .iti__dropdown-content {
  position: absolute;
  z-index: 2;
  margin-top: 3px;
  margin-left: calc(var(--iti-border-width) * -1);
  border: var(--iti-border-width) solid var(--iti-border-color);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.iti__search-input {
  width: 100%;
  border-width: 0;
  border-radius: 3px;
}
.iti__search-input + .iti__country-list {
  border-top: 1px solid var(--iti-border-color);
}
.iti__country-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.iti--inline-dropdown .iti__country-list {
  max-height: 185px;
}
.iti--flexible-dropdown-width .iti__country-list {
  white-space: nowrap;
}
@media (max-width: 500px) {
  .iti--flexible-dropdown-width .iti__country-list {
    white-space: normal;
  }
}
.iti__country {
  display: flex;
  align-items: center;
  padding: 8px var(--iti-spacer-horizontal);
  outline: none;
}
.iti__dial-code {
  color: var(--iti-dialcode-color);
}
.iti__country.iti__highlight {
  background-color: var(--iti-hover-color);
}
.iti__country-list .iti__flag, .iti__country-name {
  margin-right: var(--iti-spacer-horizontal);
}
[dir=rtl] .iti__country-list .iti__flag, [dir=rtl] .iti__country-name {
  margin-right: 0;
  margin-left: var(--iti-spacer-horizontal);
}
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover, .iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover button {
  cursor: pointer;
}
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country-primary:hover,
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country:has(+ .iti__dropdown-content:hover) .iti__selected-country-primary {
  background-color: var(--iti-hover-color);
}
.iti .iti__selected-dial-code {
  margin-left: 4px;
}
[dir=rtl] .iti .iti__selected-dial-code {
  margin-left: 0;
  margin-right: 4px;
}
.iti--container {
  position: fixed;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: var(--iti-border-width);
}
.iti--container:hover {
  cursor: pointer;
}

.iti--fullscreen-popup.iti--container {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: var(--iti-mobile-popup-margin);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.iti--fullscreen-popup .iti__dropdown-content {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
}
.iti--fullscreen-popup .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  --iti-flag-offset: 100px;
  height: var(--iti-flag-height);
  width: var(--iti-flag-width);
  border-radius: 1px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: var(--iti-path-flags-1x);
  background-repeat: no-repeat;
  background-position: var(--iti-flag-offset) 0;
  background-size: var(--iti-flag-sprite-width) var(--iti-flag-sprite-height);
}

.iti__ac {
  --iti-flag-offset: 0px;
}

.iti__ad {
  --iti-flag-offset: -16px;
}

.iti__ae {
  --iti-flag-offset: -32px;
}

.iti__af {
  --iti-flag-offset: -48px;
}

.iti__ag {
  --iti-flag-offset: -64px;
}

.iti__ai {
  --iti-flag-offset: -80px;
}

.iti__al {
  --iti-flag-offset: -96px;
}

.iti__am {
  --iti-flag-offset: -112px;
}

.iti__ao {
  --iti-flag-offset: -128px;
}

.iti__ar {
  --iti-flag-offset: -144px;
}

.iti__as {
  --iti-flag-offset: -160px;
}

.iti__at {
  --iti-flag-offset: -176px;
}

.iti__au {
  --iti-flag-offset: -192px;
}

.iti__aw {
  --iti-flag-offset: -208px;
}

.iti__ax {
  --iti-flag-offset: -224px;
}

.iti__az {
  --iti-flag-offset: -240px;
}

.iti__ba {
  --iti-flag-offset: -256px;
}

.iti__bb {
  --iti-flag-offset: -272px;
}

.iti__bd {
  --iti-flag-offset: -288px;
}

.iti__be {
  --iti-flag-offset: -304px;
}

.iti__bf {
  --iti-flag-offset: -320px;
}

.iti__bg {
  --iti-flag-offset: -336px;
}

.iti__bh {
  --iti-flag-offset: -352px;
}

.iti__bi {
  --iti-flag-offset: -368px;
}

.iti__bj {
  --iti-flag-offset: -384px;
}

.iti__bl {
  --iti-flag-offset: -400px;
}

.iti__bm {
  --iti-flag-offset: -416px;
}

.iti__bn {
  --iti-flag-offset: -432px;
}

.iti__bo {
  --iti-flag-offset: -448px;
}

.iti__bq {
  --iti-flag-offset: -464px;
}

.iti__br {
  --iti-flag-offset: -480px;
}

.iti__bs {
  --iti-flag-offset: -496px;
}

.iti__bt {
  --iti-flag-offset: -512px;
}

.iti__bw {
  --iti-flag-offset: -528px;
}

.iti__by {
  --iti-flag-offset: -544px;
}

.iti__bz {
  --iti-flag-offset: -560px;
}

.iti__ca {
  --iti-flag-offset: -576px;
}

.iti__cc {
  --iti-flag-offset: -592px;
}

.iti__cd {
  --iti-flag-offset: -608px;
}

.iti__cf {
  --iti-flag-offset: -624px;
}

.iti__cg {
  --iti-flag-offset: -640px;
}

.iti__ch {
  --iti-flag-offset: -656px;
}

.iti__ci {
  --iti-flag-offset: -672px;
}

.iti__ck {
  --iti-flag-offset: -688px;
}

.iti__cl {
  --iti-flag-offset: -704px;
}

.iti__cm {
  --iti-flag-offset: -720px;
}

.iti__cn {
  --iti-flag-offset: -736px;
}

.iti__co {
  --iti-flag-offset: -752px;
}

.iti__cr {
  --iti-flag-offset: -768px;
}

.iti__cu {
  --iti-flag-offset: -784px;
}

.iti__cv {
  --iti-flag-offset: -800px;
}

.iti__cw {
  --iti-flag-offset: -816px;
}

.iti__cx {
  --iti-flag-offset: -832px;
}

.iti__cy {
  --iti-flag-offset: -848px;
}

.iti__cz {
  --iti-flag-offset: -864px;
}

.iti__de {
  --iti-flag-offset: -880px;
}

.iti__dj {
  --iti-flag-offset: -896px;
}

.iti__dk {
  --iti-flag-offset: -912px;
}

.iti__dm {
  --iti-flag-offset: -928px;
}

.iti__do {
  --iti-flag-offset: -944px;
}

.iti__dz {
  --iti-flag-offset: -960px;
}

.iti__ec {
  --iti-flag-offset: -976px;
}

.iti__ee {
  --iti-flag-offset: -992px;
}

.iti__eg {
  --iti-flag-offset: -1008px;
}

.iti__eh {
  --iti-flag-offset: -1024px;
}

.iti__er {
  --iti-flag-offset: -1040px;
}

.iti__es {
  --iti-flag-offset: -1056px;
}

.iti__et {
  --iti-flag-offset: -1072px;
}

.iti__fi {
  --iti-flag-offset: -1088px;
}

.iti__fj {
  --iti-flag-offset: -1104px;
}

.iti__fk {
  --iti-flag-offset: -1120px;
}

.iti__fm {
  --iti-flag-offset: -1136px;
}

.iti__fo {
  --iti-flag-offset: -1152px;
}

.iti__fr {
  --iti-flag-offset: -1168px;
}

.iti__ga {
  --iti-flag-offset: -1184px;
}

.iti__gb {
  --iti-flag-offset: -1200px;
}

.iti__gd {
  --iti-flag-offset: -1216px;
}

.iti__ge {
  --iti-flag-offset: -1232px;
}

.iti__gf {
  --iti-flag-offset: -1248px;
}

.iti__gg {
  --iti-flag-offset: -1264px;
}

.iti__gh {
  --iti-flag-offset: -1280px;
}

.iti__gi {
  --iti-flag-offset: -1296px;
}

.iti__gl {
  --iti-flag-offset: -1312px;
}

.iti__gm {
  --iti-flag-offset: -1328px;
}

.iti__gn {
  --iti-flag-offset: -1344px;
}

.iti__gp {
  --iti-flag-offset: -1360px;
}

.iti__gq {
  --iti-flag-offset: -1376px;
}

.iti__gr {
  --iti-flag-offset: -1392px;
}

.iti__gt {
  --iti-flag-offset: -1408px;
}

.iti__gu {
  --iti-flag-offset: -1424px;
}

.iti__gw {
  --iti-flag-offset: -1440px;
}

.iti__gy {
  --iti-flag-offset: -1456px;
}

.iti__hk {
  --iti-flag-offset: -1472px;
}

.iti__hn {
  --iti-flag-offset: -1488px;
}

.iti__hr {
  --iti-flag-offset: -1504px;
}

.iti__ht {
  --iti-flag-offset: -1520px;
}

.iti__hu {
  --iti-flag-offset: -1536px;
}

.iti__id {
  --iti-flag-offset: -1552px;
}

.iti__ie {
  --iti-flag-offset: -1568px;
}

.iti__il {
  --iti-flag-offset: -1584px;
}

.iti__im {
  --iti-flag-offset: -1600px;
}

.iti__in {
  --iti-flag-offset: -1616px;
}

.iti__io {
  --iti-flag-offset: -1632px;
}

.iti__iq {
  --iti-flag-offset: -1648px;
}

.iti__ir {
  --iti-flag-offset: -1664px;
}

.iti__is {
  --iti-flag-offset: -1680px;
}

.iti__it {
  --iti-flag-offset: -1696px;
}

.iti__je {
  --iti-flag-offset: -1712px;
}

.iti__jm {
  --iti-flag-offset: -1728px;
}

.iti__jo {
  --iti-flag-offset: -1744px;
}

.iti__jp {
  --iti-flag-offset: -1760px;
}

.iti__ke {
  --iti-flag-offset: -1776px;
}

.iti__kg {
  --iti-flag-offset: -1792px;
}

.iti__kh {
  --iti-flag-offset: -1808px;
}

.iti__ki {
  --iti-flag-offset: -1824px;
}

.iti__km {
  --iti-flag-offset: -1840px;
}

.iti__kn {
  --iti-flag-offset: -1856px;
}

.iti__kp {
  --iti-flag-offset: -1872px;
}

.iti__kr {
  --iti-flag-offset: -1888px;
}

.iti__kw {
  --iti-flag-offset: -1904px;
}

.iti__ky {
  --iti-flag-offset: -1920px;
}

.iti__kz {
  --iti-flag-offset: -1936px;
}

.iti__la {
  --iti-flag-offset: -1952px;
}

.iti__lb {
  --iti-flag-offset: -1968px;
}

.iti__lc {
  --iti-flag-offset: -1984px;
}

.iti__li {
  --iti-flag-offset: -2000px;
}

.iti__lk {
  --iti-flag-offset: -2016px;
}

.iti__lr {
  --iti-flag-offset: -2032px;
}

.iti__ls {
  --iti-flag-offset: -2048px;
}

.iti__lt {
  --iti-flag-offset: -2064px;
}

.iti__lu {
  --iti-flag-offset: -2080px;
}

.iti__lv {
  --iti-flag-offset: -2096px;
}

.iti__ly {
  --iti-flag-offset: -2112px;
}

.iti__ma {
  --iti-flag-offset: -2128px;
}

.iti__mc {
  --iti-flag-offset: -2144px;
}

.iti__md {
  --iti-flag-offset: -2160px;
}

.iti__me {
  --iti-flag-offset: -2176px;
}

.iti__mf {
  --iti-flag-offset: -2192px;
}

.iti__mg {
  --iti-flag-offset: -2208px;
}

.iti__mh {
  --iti-flag-offset: -2224px;
}

.iti__mk {
  --iti-flag-offset: -2240px;
}

.iti__ml {
  --iti-flag-offset: -2256px;
}

.iti__mm {
  --iti-flag-offset: -2272px;
}

.iti__mn {
  --iti-flag-offset: -2288px;
}

.iti__mo {
  --iti-flag-offset: -2304px;
}

.iti__mp {
  --iti-flag-offset: -2320px;
}

.iti__mq {
  --iti-flag-offset: -2336px;
}

.iti__mr {
  --iti-flag-offset: -2352px;
}

.iti__ms {
  --iti-flag-offset: -2368px;
}

.iti__mt {
  --iti-flag-offset: -2384px;
}

.iti__mu {
  --iti-flag-offset: -2400px;
}

.iti__mv {
  --iti-flag-offset: -2416px;
}

.iti__mw {
  --iti-flag-offset: -2432px;
}

.iti__mx {
  --iti-flag-offset: -2448px;
}

.iti__my {
  --iti-flag-offset: -2464px;
}

.iti__mz {
  --iti-flag-offset: -2480px;
}

.iti__na {
  --iti-flag-offset: -2496px;
}

.iti__nc {
  --iti-flag-offset: -2512px;
}

.iti__ne {
  --iti-flag-offset: -2528px;
}

.iti__nf {
  --iti-flag-offset: -2544px;
}

.iti__ng {
  --iti-flag-offset: -2560px;
}

.iti__ni {
  --iti-flag-offset: -2576px;
}

.iti__nl {
  --iti-flag-offset: -2592px;
}

.iti__no {
  --iti-flag-offset: -2608px;
}

.iti__np {
  --iti-flag-offset: -2624px;
}

.iti__nr {
  --iti-flag-offset: -2640px;
}

.iti__nu {
  --iti-flag-offset: -2656px;
}

.iti__nz {
  --iti-flag-offset: -2672px;
}

.iti__om {
  --iti-flag-offset: -2688px;
}

.iti__pa {
  --iti-flag-offset: -2704px;
}

.iti__pe {
  --iti-flag-offset: -2720px;
}

.iti__pf {
  --iti-flag-offset: -2736px;
}

.iti__pg {
  --iti-flag-offset: -2752px;
}

.iti__ph {
  --iti-flag-offset: -2768px;
}

.iti__pk {
  --iti-flag-offset: -2784px;
}

.iti__pl {
  --iti-flag-offset: -2800px;
}

.iti__pm {
  --iti-flag-offset: -2816px;
}

.iti__pr {
  --iti-flag-offset: -2832px;
}

.iti__ps {
  --iti-flag-offset: -2848px;
}

.iti__pt {
  --iti-flag-offset: -2864px;
}

.iti__pw {
  --iti-flag-offset: -2880px;
}

.iti__py {
  --iti-flag-offset: -2896px;
}

.iti__qa {
  --iti-flag-offset: -2912px;
}

.iti__re {
  --iti-flag-offset: -2928px;
}

.iti__ro {
  --iti-flag-offset: -2944px;
}

.iti__rs {
  --iti-flag-offset: -2960px;
}

.iti__ru {
  --iti-flag-offset: -2976px;
}

.iti__rw {
  --iti-flag-offset: -2992px;
}

.iti__sa {
  --iti-flag-offset: -3008px;
}

.iti__sb {
  --iti-flag-offset: -3024px;
}

.iti__sc {
  --iti-flag-offset: -3040px;
}

.iti__sd {
  --iti-flag-offset: -3056px;
}

.iti__se {
  --iti-flag-offset: -3072px;
}

.iti__sg {
  --iti-flag-offset: -3088px;
}

.iti__sh {
  --iti-flag-offset: -3104px;
}

.iti__si {
  --iti-flag-offset: -3120px;
}

.iti__sj {
  --iti-flag-offset: -3136px;
}

.iti__sk {
  --iti-flag-offset: -3152px;
}

.iti__sl {
  --iti-flag-offset: -3168px;
}

.iti__sm {
  --iti-flag-offset: -3184px;
}

.iti__sn {
  --iti-flag-offset: -3200px;
}

.iti__so {
  --iti-flag-offset: -3216px;
}

.iti__sr {
  --iti-flag-offset: -3232px;
}

.iti__ss {
  --iti-flag-offset: -3248px;
}

.iti__st {
  --iti-flag-offset: -3264px;
}

.iti__sv {
  --iti-flag-offset: -3280px;
}

.iti__sx {
  --iti-flag-offset: -3296px;
}

.iti__sy {
  --iti-flag-offset: -3312px;
}

.iti__sz {
  --iti-flag-offset: -3328px;
}

.iti__tc {
  --iti-flag-offset: -3344px;
}

.iti__td {
  --iti-flag-offset: -3360px;
}

.iti__tg {
  --iti-flag-offset: -3376px;
}

.iti__th {
  --iti-flag-offset: -3392px;
}

.iti__tj {
  --iti-flag-offset: -3408px;
}

.iti__tk {
  --iti-flag-offset: -3424px;
}

.iti__tl {
  --iti-flag-offset: -3440px;
}

.iti__tm {
  --iti-flag-offset: -3456px;
}

.iti__tn {
  --iti-flag-offset: -3472px;
}

.iti__to {
  --iti-flag-offset: -3488px;
}

.iti__tr {
  --iti-flag-offset: -3504px;
}

.iti__tt {
  --iti-flag-offset: -3520px;
}

.iti__tv {
  --iti-flag-offset: -3536px;
}

.iti__tw {
  --iti-flag-offset: -3552px;
}

.iti__tz {
  --iti-flag-offset: -3568px;
}

.iti__ua {
  --iti-flag-offset: -3584px;
}

.iti__ug {
  --iti-flag-offset: -3600px;
}

.iti__us {
  --iti-flag-offset: -3616px;
}

.iti__uy {
  --iti-flag-offset: -3632px;
}

.iti__uz {
  --iti-flag-offset: -3648px;
}

.iti__va {
  --iti-flag-offset: -3664px;
}

.iti__vc {
  --iti-flag-offset: -3680px;
}

.iti__ve {
  --iti-flag-offset: -3696px;
}

.iti__vg {
  --iti-flag-offset: -3712px;
}

.iti__vi {
  --iti-flag-offset: -3728px;
}

.iti__vn {
  --iti-flag-offset: -3744px;
}

.iti__vu {
  --iti-flag-offset: -3760px;
}

.iti__wf {
  --iti-flag-offset: -3776px;
}

.iti__ws {
  --iti-flag-offset: -3792px;
}

.iti__xk {
  --iti-flag-offset: -3808px;
}

.iti__ye {
  --iti-flag-offset: -3824px;
}

.iti__yt {
  --iti-flag-offset: -3840px;
}

.iti__za {
  --iti-flag-offset: -3856px;
}

.iti__zm {
  --iti-flag-offset: -3872px;
}

.iti__zw {
  --iti-flag-offset: -3888px;
}

.iti__globe {
  background-image: var(--iti-path-globe-1x);
  background-size: contain;
  background-position: right;
  box-shadow: none;
  height: 19px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2x) {
  .iti__flag {
    background-image: var(--iti-path-flags-2x);
  }
  .iti__globe {
    background-image: var(--iti-path-globe-2x);
  }
}
/* Import IntlTelInput base styles */

:root {
  --color-translucent-emphasis-300: rgba(247, 247, 247, 0.1);
  --color-text-body-primary: #F7F7F7;
  --color-translucent-inverse-600: rgba(13, 13, 13, 0.48);
  --color-translucent-standard-500: rgba(247, 247, 247, 0.24);
  --color-translucent-standard-700: rgba(247, 247, 247, 0.64);
  --color-status-fatal-bright: #FF637F;
}


.iti {
  font-family: var(--font-body-primary, Roboto, sans-serif);
  font-size: 14px;
  width: 100%;
}

/* Override country list styling */
.iti__country-list {
  background-color: #1C1C1C;
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 8px 0;
  max-height: 350px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-family: var(--font-body-primary, Roboto, sans-serif);
  margin-top: 8px;
}

.iti__divider {
  border-bottom: 1px solid #333;
}

.iti__country.iti__highlight,
.iti__country:hover {
  background-color: #2A2A2A;
}

.iti--container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.iti__flag-container,
.iti__selected-flag {
  background-color: #1C1C1C;
  border-radius: 8px 0 0 8px;
  border-right: 1px solid var(--color-translucent-standard-500);
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.iti__selected-flag:hover {
  background-color: #2A2A2A;
}

.iti__arrow {
  border: none;
  width: 8px;
  height: 8px;
  border-right: 2px solid var(--color-text-body-primary);
  border-bottom: 2px solid var(--color-text-body-primary);
  transform: rotate(45deg);
  margin-top: -4px;
  margin-left: 12px;
  transition: transform 0.2s ease;
}

.iti__arrow--up {
  transform: rotate(-135deg);
  margin-top: 0;
}

.iti__country-name,
.iti__dial-code {
  color: var(--color-text-body-primary);
  font-size: 14px;
  font-family: var(--font-body-primary, Roboto, sans-serif);
}

.iti__search-container {
  padding: 8px 12px;
  background-color: #1C1C1C;
}



.iti__search-input:hover {
  border-color: var(--color-translucent-inverse-600);
}

.iti__search-input:focus {
  border-color: #F7F7F7;
  outline: none;
}

.iti__country {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Input styling */
.iti input {
  background-color: #1C1C1C;
  color: #fff;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--color-translucent-standard-500);
  font-size: 14px;
  padding: 0 12px 0 90px;
  font-family: var(--font-body-primary, Roboto, sans-serif);
  transition: all 0.2s ease-in-out;
}

.iti input:hover {
  border-color: var(--color-translucent-standard-500);
}

.iti input:focus {
  border-color: #F7F7F7;
  outline: none;
}

.iti input.is-invalid {
  border-color: var(--color-status-fatal-bright);
}

/* Fix full width */
.iti {
  width: 100%;
  display: block;
}

/* Adjust spacing for country flag and dial code */
.iti__selected-flag {
  padding-left: 12px;
}

.iti__flag-container {
  margin-right: 8px;
}

.iti__dropdown-content {
  background-color: #1C1C1C;
}

.iti--inline-dropdown .iti__dropdown-content {
  border-color: #1C1C1C;
  border-radius: 12px;
}

/* .iti__tel-input { 
  padding-left: 90px !important;
} */

/* .iti__selected-dial-code {
  margin-left: 6px;
} */

.iti__flag {
  margin-left: 6px;
}

.iti__search-input {
  background-color: #1C1C1C;
  color: #fff;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 8px 12px !important;
  width: 100%;
  font-size: 14px;
  font-family: var(--font-body-primary, Roboto, sans-serif);
}

@font-face {
font-family: '__headingPrimary_ae94b2';
src: url(/_next/static/media/d20615ea8c7a3a2d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__headingPrimary_ae94b2';
src: url(/_next/static/media/5a96f22413524027-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__headingPrimary_ae94b2';
src: url(/_next/static/media/0794e24aed2570ec-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__headingPrimary_Fallback_ae94b2';src: local("Arial");ascent-override: 95.93%;descent-override: 30.26%;line-gap-override: 0.00%;size-adjust: 102.79%
}.__className_ae94b2 {font-family: '__headingPrimary_ae94b2', '__headingPrimary_Fallback_ae94b2'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e4832d224fa78fca-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/50c46292b7e41e4c-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/81bdca91991057f4-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ba107e9862e2fbbd-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/8b6db60e34e611e2-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/42cc98a089bcef73-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9e57792bbf96ad9d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/bfe0833941d814cf-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/0f3a385557f1712f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e4832d224fa78fca-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/50c46292b7e41e4c-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/81bdca91991057f4-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ba107e9862e2fbbd-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/8b6db60e34e611e2-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/42cc98a089bcef73-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9e57792bbf96ad9d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/bfe0833941d814cf-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/0f3a385557f1712f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e4832d224fa78fca-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/50c46292b7e41e4c-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/81bdca91991057f4-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ba107e9862e2fbbd-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/8b6db60e34e611e2-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/42cc98a089bcef73-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9e57792bbf96ad9d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/bfe0833941d814cf-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/0f3a385557f1712f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/74c003a2abab0c4f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/24c15609eaa28576-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/84602850c8fd81c3-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/90da053edc2b7de3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ac3b7908202f8517-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/627d916fd739a539-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b5ee789b512e4d1b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2c07349e02a7b712-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_a15c3d';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/47cbc4e2adbc5db9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_a15c3d';src: local("Arial");ascent-override: 92.98%;descent-override: 24.47%;line-gap-override: 0.00%;size-adjust: 99.78%
}.__className_a15c3d {font-family: '__Roboto_a15c3d', '__Roboto_Fallback_a15c3d'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/dc435e549ca3519b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/52767bb1771bc9fa-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e994445a0af08eeb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b124db59437ca151-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ab7751b4e2fe67e8-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0fbab36608103a05-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/dc435e549ca3519b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/52767bb1771bc9fa-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e994445a0af08eeb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b124db59437ca151-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ab7751b4e2fe67e8-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0fbab36608103a05-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/77ce01c003603e77-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ccd5f404b5d91fc0-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/78bcd7d80ba05af5-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a9ae0bb0e1e9484e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5269767769affeeb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0a7d1127b1849c3a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/77ce01c003603e77-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ccd5f404b5d91fc0-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/78bcd7d80ba05af5-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a9ae0bb0e1e9484e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/5269767769affeeb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Mono_79e192';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0a7d1127b1849c3a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Mono_Fallback_79e192';src: local("Arial");ascent-override: 77.84%;descent-override: 20.13%;line-gap-override: 0.00%;size-adjust: 134.61%
}.__className_79e192 {font-family: '__Roboto_Mono_79e192', '__Roboto_Mono_Fallback_79e192'
}

